import styled from '@emotion/styled';
import { COLORS } from '../styles/settings/global';

const SDecorator = styled.div`
  width: 50px;
  height: 8px;
  border-radius: 4px;
  background-color: ${COLORS.SECONDARY};
`;

export default SDecorator;
