import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS } from '../../styles/settings/global';
import { mq } from '../../styles/functions/mq';
import { Heading3, HeadingMinor } from '../Headings';
import Button from '../Button';
import Container from '../Container';
import Pill from '../Pill';

// #region styles
const SFeatured = styled.section`
  padding: 80px 30px;
  background-color: ${COLORS.STRONG};

  ${mq.tablet} {
    display: grid;
    grid-template-columns: 33% auto;
    grid-template-rows: 1fr [top] auto [middle] auto [bottom] auto 1fr;
  }

  ${mq.landscape} {
    padding: 90px 110px 90px 120px;
  }

  ${mq.desktop} {
    padding: 120px 180px;
  }
`;

const SPills = styled.div`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    top: 0;
    right: 50px;
    display: flex;
    transform: translateY(-50%) scale(0.75);
  }

  ${mq.desktop} {
    transform: translateY(-50%) scale(1);
  }
`;

const SHeadingMinor = styled(HeadingMinor)`
  color: ${COLORS.LIGHT};

  ${mq.tablet} {
    grid-column: 2;
    grid-row: top;
    align-self: start;
  }
`;

const SImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${mq.tablet} {
    grid-column: 1;
    grid-row: 1 / span 5;
    margin-top: 0;
    align-self: start;
    justify-self: start;
  }
`;

const SImage = styled.img`
  ${mq.desktop} {
    width: 280px;
  }
`;

const SHeading3 = styled(Heading3)`
  color: white;

  ${mq.tablet} {
    grid-column: 2;
    grid-row: middle;
  }
`;

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  ${mq.tablet} {
    grid-column: 2;
    grid-row: bottom;
    justify-content: flex-start;
    align-self: end;
    margin-top: 33px;
  }

  ${mq.landscape} {
    margin-top: 50px;
  }
`;

const CButton = css`
  color: white;
`;
// endregion

const Featured = ({ title, buttonText, imageUrl, imageAlt, onClick }) => (
  <Container>
    <SFeatured>
      <SPills>
        <Pill
          width={44}
          height={20}
          colors={[COLORS.PRIMARY, COLORS.SECONDARY]}
        />
        <Pill
          width={88}
          height={20}
          colors={[COLORS.PRIMARY, COLORS.SECONDARY]}
        />
      </SPills>
      <SHeadingMinor>Featured</SHeadingMinor>
      {title && <SHeading3>{title}</SHeading3>}
      {imageUrl && (
        <SImageWrapper>
          <SImage src={imageUrl} alt={imageAlt} />
        </SImageWrapper>
      )}
      <SButtonWrapper>
        <Button
          color={COLORS.SECONDARY}
          gap="side"
          onClick={onClick}
          cssStyle={CButton}
        >
          {buttonText}
        </Button>
      </SButtonWrapper>
    </SFeatured>
  </Container>
);

export default Featured;
