import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import Container from '../Container';
import { Heading3, Heading4, HeadingMinor } from '../Headings';
import Button from '../Button';

// #region styles
const SDecorator = styled.div`
  ${mq.tablet} {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 13px;
    background-color: ${COLORS.PRIMARY};
  }
`;

const SProduct = styled.section`
  position: relative;
  padding: 40px 30px 60px;

  ${mq.tablet} {
    display: flex;
    max-width: 817px;
    margin: 60px auto 0;
    padding: 0px 60px 60px;
  }

  ${mq.landscape} {
    margin: 110px auto 0;
    padding: 0px 60px 120px;
  }

  ${mq.desktop} {
    max-width: 945px;
  }
`;

const SVerbal = styled.div`
  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    position: relative;
    padding: 0;
    margin-top: 20px;
    color: ${COLORS.STRONG};

    strong {
      color: ${COLORS.PRIMARY};
      font-style: normal;
      font-weight: 600;
    }

    ${mq.tablet} {
      padding-left: 27px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: linear-gradient(
          to right,
          ${COLORS.PRIMARY} 0 50%,
          ${COLORS.SECONDARY} 50% 100%
        );
      }
    }
  }
`;

const SFootnote = styled(Heading4)`
  margin-top: 20px;
  text-align: center;

  ${mq.tablet} {
    margin-top: 40px;
    text-align: left;
  }
`;

const SCta = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${mq.tablet} {
    justify-content: flex-start;
  }
`;

const SGraphical = styled.div`
  text-align: center;

  ${mq.tablet} {
    min-width: 215px;
    align-self: center;
    text-align: right;
  }
`;

const SHandImage = styled.img`
  max-width: 77px;
  margin: 60px auto 0;

  ${mq.tablet} {
    margin: 0 50px 150px 0;
  }

  ${mq.landscape} {
    margin: 0 0 150px 0;
  }
`;
// endregion

const Product = ({ title, description, footNote, button }) => (
  <Container>
    <SDecorator />
    <SProduct>
      <SVerbal>
        <HeadingMinor>Product</HeadingMinor>
        {title && <Heading3>{title}</Heading3>}

        {description && (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}

        {footNote && <SFootnote>{footNote}</SFootnote>}

        <SCta>
          <Link
            to={button.url}
            target={button.newTab ? '_blank' : null}
            onClick={() => {
              trackCustomEvent({
                category: 'internal-cta',
                action: 'learn-more',
                label: 'home_to_product_cta',
              });
            }}
          >
            <Button color={COLORS.PRIMARY} gap="side">
              {button.text}
            </Button>
          </Link>
        </SCta>
      </SVerbal>

      <SGraphical>
        <SHandImage
          src="/images/hand-icon.png"
          alt="Silhouette of hand made of blue dashes and lines "
        />
      </SGraphical>
    </SProduct>
  </Container>
);

export default Product;
