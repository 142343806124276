import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { mq } from '../styles/functions/mq';

import Layout from '../components/Layout';
import DownloadModal from '../components/DownloadModal';
import Featured from '../components/index/Featured';
import Logos from '../components/Logos';
import Hero from '../components/index/Hero';
import Product from '../components/index/Product';
import Podcast from '../components/Podcast';
import GetInTouch from '../components/GetInTouch';
import About from '../components/index/About';
import Testimonial from '../components/index/Testimonial';
import Container from '../components/Container';
import { HeadingMinor } from '../components/Headings';
import IndexPublication from '../components/index/IndexPublication';

import Banner from '../components/Banner';

// #region styles
const SDecoration = styled.img`
  display: none;

  ${mq.landscape} {
    display: block;
    position: absolute;
    top: -40px;
    left: 50px;
  }
`;

const SIndexPublications = styled.div`
  padding: 80px 30px;

  ${mq.landscape} {
    padding: 130px 90px 80px 175px;
  }

  ${mq.desktop} {
    padding: 100px 210px 130px 240px;
  }
`;
// endregion

class IndexPage extends React.Component {
  state = {
    modal: {
      active: false,
      category: '',
      title: '',
      description: '',
      url: '',
    },
  };

  render = () => {
    const { data, location } = this.props;
    const { modal } = this.state;

    const {
      meta,
      banner,
      hero,
      product,
      testimonial,
      featured,
      imageDivider,
      about,
      partners,
    } = data.homeYaml;

    const getInTouch = data.getInTouchYaml;
    const podcast = data.podcastYaml;
    const publications = data.publicationsYaml.publications.filter(
      (pub) => pub.showOnHomePage === 'yes',
    );

    return (
      <Layout meta={meta} location={location}>
        <DownloadModal
          active={modal.active}
          category={modal.category}
          title={modal.title}
          description={modal.description}
          url={modal.url}
          data={data}
          onClose={() => {
            this.setState({
              modal: {
                active: false,
              },
            });
          }}
        />

        <Banner
          heading={banner.heading}
          copy={banner.copy}
          show={banner.show}
          buttonText={banner.file?.publicURL && banner.buttonText}
          onClick={() => {
            this.setState({
              modal: {
                active: true,
                category: 'Litmus Device Census Report',
                description:
                  'Thank you for your interest in our Device Census Report. Please provide your information below to start your download.',
                url: banner.file?.publicURL,
              },
            });
            trackCustomEvent({
              category: 'publication',
              action: 'request',
              label: 'Litmus Device Census Report',
            });
          }}
        />

        <Hero
          title={hero.title}
          description={hero.description}
          description2={hero.description2}
          imageUrl={hero.image?.publicURL}
          imageAlt={hero.imageAlt}
        />

        <Product
          title={product.title}
          description={product.description?.html}
          footNote={product.footNote}
          button={product.button}
        />

        <Testimonial
          title={testimonial.title}
          description={testimonial.description?.html}
          buttons={testimonial.buttons}
          image={testimonial.image.image?.publicURL}
          imageTitle={testimonial.image.title}
          text={testimonial.image.testimonial}
          author={testimonial.image.author}
          authorAbout={testimonial.image.authorAbout}
        />

        {/* <Podcast podcast={podcast} /> */}

        <Featured
          title={featured.title}
          imageUrl={featured.image?.publicURL}
          imageAlt={featured.imageAlt}
          buttonText={featured.button.text}
          onClick={() => {
            this.setState({
              modal: {
                active: true,
                category: 'Litmus Device Census Report',
                description:
                  'Thank you for your interest in our Device Census Report. Please provide your information below to start your download.',
                url: featured.button.file?.publicURL,
              },
            });
            trackCustomEvent({
              category: 'publication',
              action: 'request',
              label: 'Litmus Device Census Report',
            });
          }}
        />

        <Container>
          <SIndexPublications>
            <SDecoration src="../images/7-DataPattern_4.png" />
            <HeadingMinor>Publications</HeadingMinor>

            {publications.map((pbl, index) => {
              const isDownloadable = pbl.button.type === 'download';
              const buttonLabel =
                pbl.button.text || isDownloadable ? 'Download' : 'Read More';
              const imageUrl = pbl.image?.publicURL;
              return (
                <IndexPublication
                  key={index}
                  title={pbl.title}
                  description={pbl.description}
                  imageUrl={imageUrl}
                  imageAlt={pbl.imageAlt}
                  buttonText={buttonLabel}
                  location={pbl.location}
                  authors={pbl.authors}
                  onClick={() => {
                    if (isDownloadable) {
                      this.setState({
                        modal: {
                          active: true,
                          category: pbl.title,
                          description: pbl.title,
                          url: pbl.button.file?.publicURL,
                        },
                      });
                      trackCustomEvent({
                        category: 'publication',
                        action: 'request',
                        label: pbl.title,
                      });
                    } else {
                      window.open(pbl.button.url, '_blank');
                      trackCustomEvent({
                        category: 'publication',
                        action: 'open',
                        label: pbl.title,
                      });
                    }
                  }}
                />
              );
            })}
          </SIndexPublications>
        </Container>

        {imageDivider.image?.publicURL && (
          <Container>
            <img
              src={imageDivider.image.publicURL}
              style={{ width: '100%' }}
              alt={imageDivider.imageAlt}
            />
          </Container>
        )}

        <About
          title={about.title}
          description={about.description}
          buttons={about.buttons}
        />

        <GetInTouch
          title={getInTouch.title}
          buttons={getInTouch.buttons}
          location="home"
        />

        <Logos title="PARTNERS" items={partners.list} />
      </Layout>
    );
  };
}

export default IndexPage;

// #region graphql
export const pageQuery = graphql`
  query IndexPage {
    homeYaml {
      meta {
        title
        description
      }

      banner {
        show
        heading
        copy
        file {
          publicURL
        }
        buttonText
      }

      hero {
        title
        description
        description2
        image {
          publicURL
        }
        imageAlt
      }

      product {
        title
        description {
          html
        }
        footNote
        button {
          text
          url
          newTab
        }
      }

      testimonial {
        title
        description {
          html
        }
        buttons {
          text
          url
          newTab
        }
        image {
          image {
            publicURL
          }
          title
          testimonial
          author
          authorAbout
        }
      }

      featured {
        title
        image {
          publicURL
        }
        imageAlt
        button {
          text
          file {
            publicURL
          }
        }
      }

      imageDivider {
        image {
          publicURL
        }
        imageAlt
      }

      about {
        title
        description
        buttons {
          text
          url
          newTab
          file {
            publicURL
          }
        }
      }

      partners {
        list {
          name
          image {
            publicURL
          }
          url
          newTab
        }
      }
    }

    podcastYaml {
      title
      image {
        publicURL
      }
      imageAlt
      description {
        html
      }
      button {
        text
        url
        newTab
      }
      applePodcastsLink
      stitcherLink
    }

    publicationsYaml {
      publications {
        title
        image {
          publicURL
        }
        imageAlt
        location
        authors
        description
        button {
          type
          file {
            publicURL
          }
          url
          text
        }
        showOnHomePage
      }
    }

    getInTouchYaml {
      title
      buttons {
        text
        url
        newTab
      }
    }
  }
`;
// endregion
