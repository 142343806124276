import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { mq } from '../../styles/functions/mq';
import Button from '../Button';
import Container from '../Container';
import { Heading3, HeadingMinor } from '../Headings';
import SmartLink from '../SmartLink';

// #region styles
const SAbout = styled.section`
  padding: 80px 30px;

  ${mq.tablet} {
    padding: 80px 60px 120px;
  }

  ${mq.landscape} {
    padding: 80px 120px 120px;
  }

  ${mq.desktop} {
    padding: 80px 240px 120px;
  }
`;

const SHeading3 = styled(Heading3)`
  max-width: 780px;
`;

const SDescription = styled.p`
  margin-top: 20px;

  ${mq.tablet} {
    margin-top: 30px;
    max-width: 660px;
  }

  ${mq.landscape} {
    margin-top: 40px;
  }

  ${mq.desktop} {
    margin-top: 50px;
  }
`;

const SButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.tablet} {
    flex-direction: row;
  }
`;

const CButton = css`
  margin-top: 40px;

  ${mq.tablet} {
    margin-right: 40px;
  }

  ${mq.landscape} {
    margin-top: 60px;
  }

  ${mq.desktop} {
    margin-top: 80px;
  }
`;
// endregion

const About = ({ title, description, buttons }) => (
  <Container>
    <SAbout>
      <HeadingMinor>About Litmus</HeadingMinor>
      {title && <SHeading3>{title}</SHeading3>}

      {description && <SDescription>{description}</SDescription>}

      {buttons.length > 0 && (
        <SButtons>
          {buttons.map((button, index) => (
            <SmartLink {...button} key={index}>
              <Button
                cssStyle={CButton}
                onClick={() => {
                  const textLc = button.text.toLowerCase().trim();
                  trackCustomEvent({
                    category: 'contact interest',
                    action: 'home',
                    label: textLc === 'about us' ? 'about us cta' : textLc,
                  });
                }}
              >
                {button.text}
              </Button>
            </SmartLink>
          ))}
        </SButtons>
      )}
    </SAbout>
  </Container>
);

export default About;
