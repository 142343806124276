import React from 'react';
import styled from '@emotion/styled';
import Container from '../Container';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import { Heading1 } from '../Headings';
import Background from '../Background';
import Decorator from '../Decorator';

// #region styles
const SHero = styled.section`
  display: flex;
  flex-direction: column;

  ${mq.tablet} {
    flex-direction: row;
    max-height: 550px;
  }
`;

const SHeroImage = styled.img`
  width: 100%;
  height: 68.75vw;
  max-height: 480px;
  object-fit: cover;

  ${mq.tablet} {
    width: 50%;
    max-height: inherit;
    flex-shrink: 0;
  }
`;

const SDecorator = styled(Decorator)`
  margin: 20px 0 30px;
`;

const SHeroContent = styled.div`
  position: relative;
  padding: 40px 30px 80px;
  color: white;
  background: ${COLORS.PRIMARY};

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 50px;
  }
`;

const SHeading1 = styled(Heading1)`
  ${mq.tablet} {
    max-width: 350px;
  }
`;

const SHeroDescription = styled.p`
  font-size: 16px;
  font-weight: bold;

  ${mq.tablet} {
    max-width: 405px;
    font-size: 18px;
  }

  ${mq.desktop} {
    max-width: 500px;
    font-size: 20px;
  }
`;

const SHeroDescription2 = styled.p`
  ${mq.tablet} {
    max-width: 350px;
    font-size: 14px;
  }

  ${mq.desktop} {
    max-width: 380px;
    font-size: 16px;
  }

  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;

const SDataPattern = styled.div`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    bottom: -70px;
    right: 30px;
    width: 75px;
    height: 183px;
  }

  ${mq.desktop} {
    right: 50px;
  }
`;

// endregion

const Hero = ({ title, description, description2, imageUrl, imageAlt }) => {
  const hasTitleOrAnyDescription = Boolean(
    title || description || description2,
  );

  if (!hasTitleOrAnyDescription && !imageUrl) return null;

  return (
    <Container>
      <SHero>
        <SHeroImage src={imageUrl} alt={imageAlt} />
        <SHeroContent>
          {title && <SHeading1>{title}</SHeading1>}
          {hasTitleOrAnyDescription && <SDecorator />}
          {description && <SHeroDescription>{description}</SHeroDescription>}
          {description2 && (
            <SHeroDescription2>{description2}</SHeroDescription2>
          )}
          <SDataPattern>
            <Background src="/images/2-DataPattern_1.png" size="contain" />
          </SDataPattern>
        </SHeroContent>
      </SHero>
    </Container>
  );
};

export default Hero;
