import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../styles/functions/mq';
import Button from './Button';
import Container from './Container';
import { Heading3 } from './Headings';
import { COLORS } from '../styles/settings/global';

// #region styles
const SBanner = styled.div`
  padding: 35px 50px;
  background-color: #e6e6e6;
  background-image: url('/images/banner-bg.svg');
  background-size: 90rem;
  background-position: center;
  background-blend-mode: screen;
  text-align: center;
`;
const SBannerInner = styled.div`
  max-width: 730px;
  margin: auto;
`;
const SContent = styled.p`
  padding: 10px 0 0;
  font-weight: 600;
  line-height: 1.43em;
  letter-spacing: 0.03em;
`;
const SCta = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;
const SHeading = styled(Heading3)`
  color: ${COLORS.PRIMARY};

  ${mq.landscape} {
    font-size: 34px;
  }
`;
// endregion

const Banner = ({ heading, copy, show, buttonText, onClick }) => {
  if (!show) return null;

  return (
    <Container>
      <SBanner>
        <SBannerInner>
          {heading && <SHeading>{heading}</SHeading>}
          {copy && <SContent>{copy}</SContent>}
          {buttonText && (
            <SCta>
              <Button color={COLORS.PRIMARY} gap="vertical" onClick={onClick}>
                {buttonText}
              </Button>
            </SCta>
          )}
        </SBannerInner>
      </SBanner>
    </Container>
  );
};

export default Banner;
