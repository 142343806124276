import React from 'react';
import styled from '@emotion/styled';
import { COLORS, FONTS } from '../../styles/settings/global';
import { mq } from '../../styles/functions/mq';
import { Heading3 } from '../Headings';
import Button from '../Button';

// #region styles
const SIndexPublication = styled.article`
  &:nth-of-type(n + 2) {
    margin-top: 60px;

    ${mq.desktop} {
      margin-top: 120px;
    }
  }
`;

const SLeadPhotoMobile = styled.img`
  width: 100%;
  margin-bottom: 20px;

  ${mq.tablet} {
    display: none;
  }
`;

const SLeadPhoto = styled.img`
  display: none;

  ${mq.tablet} {
    display: block;
    align-self: flex-start;
    max-width: 40%;
    margin-right: 30px;
  }

  ${mq.landscape} {
    max-width: 340px;
    margin-right: 45px;
  }

  ${mq.desktop} {
    max-width: 410px;
    margin-right: 60px;
  }
`;

const SLocation = styled.div`
  margin-top: 20px;
  color: ${COLORS.PRIMARY};
  font-size: 14px;
  font-family: ${FONTS.APERCU};
  font-weight: bold;
  text-transform: uppercase;
`;

const SAuthors = styled.div`
  margin-top: 12px;
  color: ${COLORS.PRIMARY};
  font-family: ${FONTS.APERCU};
  font-weight: bold;
  text-decoration: underline;

  ${mq.tablet} {
    margin-top: 5px;
  }
`;

const SContent = styled.div`
  ${mq.tablet} {
    display: flex;
    margin-top: 35px;
  }

  ${mq.landscape} {
    margin-top: 50px;
  }

  ${mq.tablet} {
    margin-top: 35px;
  }
`;

const SDescription = styled.div`
  margin-top: 35px;
  color: ${COLORS.STRONG};

  ${mq.tablet} {
    margin-top: 0;
    font-size: 10px;
  }

  ${mq.desktop} {
    font-size: 12px;
  }
`;

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${mq.landscape} {
    margin-top: 60px;
  }

  ${mq.desktop} {
    margin-top: 80px;
  }
`;
// endregion

const IndexPublication = ({
  title,
  description,
  imageUrl,
  imageAlt,
  buttonText,
  location,
  authors,
  onClick,
}) => (
  <SIndexPublication>
    {imageUrl && <SLeadPhotoMobile src={imageUrl} alt={imageAlt} />}
    {title && <Heading3>{title}</Heading3>}
    {location && <SLocation>{location}</SLocation>}
    {authors && <SAuthors>{authors}</SAuthors>}
    {imageUrl || description ? (
      <SContent>
        {imageUrl && <SLeadPhoto src={imageUrl} alt={imageAlt} />}
        {description && <SDescription>{description}</SDescription>}
      </SContent>
    ) : null}
    {buttonText && (
      <SButtonWrapper>
        <Button gap="side" onClick={onClick}>
          {buttonText}
        </Button>
      </SButtonWrapper>
    )}
  </SIndexPublication>
);

export default IndexPublication;
