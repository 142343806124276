import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { COLORS, FONTS } from '../../styles/settings/global';
import { mq } from '../../styles/functions/mq';
import Container from '../Container';
import Button from '../Button';
import { Heading3 } from '../Headings';
import Background from '../Background';
import SmartLink from '../SmartLink';

// #region styles
const STestimonial = styled.section`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 13px;
    background-color: ${COLORS.PRIMARY};

    ${mq.tablet} {
      height: 50%;
    }
  }

  ${mq.tablet} {
    display: flex;
    margin-bottom: 75px;
  }

  ${mq.landscape} {
    margin-bottom: 145px;
  }
`;

const SContent = styled.div`
  padding: 0 30px;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${mq.landscape} {
    padding-left: 66px;
    padding-right: 66px;
  }

  ${mq.desktop} {
    max-width: 610px;
  }
`;

const SContentPattern = styled(Background)`
  display: none;

  ${mq.tablet} {
    display: block;
    position: absolute;
    right: 50px;
    top: 100%;
    width: 79px;
    height: 100px;
  }

  ${mq.landscape} {
    width: 117px;
    height: 150px;
  }
`;

const SDescription = styled.div`
  color: ${COLORS.STRONG};
  padding: 0;

  p {
    margin-top: 20px;
  }

  strong {
    font-style: normal;
    font-weight: 600;
    color: ${COLORS.PRIMARY};
  }
`;

const SCta = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;

  ${mq.tablet} {
    text-align: left;
  }
`;

const SButton = styled(Button)`
  margin-top: 20px;
  min-width: 190px;

  ${mq.tablet} {
    margin-top: 30px;
    min-width: 220px;
  }
`;

const SPhoto = styled.div`
  position: relative;
  height: 100vw;
  max-height: 480px;
  margin-top: 80px;

  ${mq.tablet} {
    width: 52%;
    margin-top: 0;
    order: -1;
    flex-shrink: 0;
  }

  ${mq.landscape} {
    max-height: 640px;
  }
`;

const SPhotoImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;

  ${mq.tablet} {
    object-position: 40% center;
  }

  ${mq.desktop} {
    object-position: 18% center;
  }
`;

const SPhotoPattern = styled(Background)`
  position: relative;
  height: 320px;
  margin-left: auto;
  margin-right: auto;

  ${mq.tablet} {
    max-width: 460px;
  }

  ${mq.landscape} {
    max-width: 600px;
    margin-top: 60px;
  }

  ${mq.desktop} {
    max-width: 680px;
    margin-top: 80px;
  }
`;

const SImageTitle = styled.div`
  position: absolute;
  bottom: 0;
  padding: 15px 30px;
  color: white;
  font-family: ${FONTS.APERCU};

  ${mq.tablet} {
    bottom: auto;
    top: 0;
    padding: 15px 30px;
  }

  ${mq.landscape} {
    padding: 18px 44px;
  }

  ${mq.desktop} {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const SQuotationWrapper = styled.div`
  padding: 35px 30px 80px;
  color: white;
  background-color: ${COLORS.PRIMARY};

  ${mq.tablet} {
    position: absolute;
    left: 0;
    top: 50%;
    width: 52%;
    padding: 15px 30px;
  }

  ${mq.landscape} {
    max-width: 390px;
    padding: 18px 44px;
  }

  ${mq.desktop} {
    max-width: 520px;
    padding: 30px 30px 30px 60px;
  }
`;

const SAuthor = styled.div`
  margin-top: 20px;
  font-family: ${FONTS.APERCU};
  font-size: 14px;
  font-weight: bold;
`;

const SAuthorAbout = styled.div`
  margin-top: 5px;
  font-size: 12px;
  white-space: pre-line;
`;
// endregion

const Testimonial = ({
  title,
  description,
  buttons,
  image,
  imageTitle,
  text,
  author,
  authorAbout,
}) => {
  const titleColored = useMemo(
    () =>
      title.split('+').map((val, index) =>
        index === 0 ? (
          val
        ) : (
          <React.Fragment key={index}>
            <span style={{ color: COLORS.SECONDARY }}>+</span>
            {val}
          </React.Fragment>
        ),
      ),
    [title],
  );

  return (
    <Container>
      <STestimonial>
        <SContent>
          {titleColored && <Heading3 type="h3">{titleColored}</Heading3>}

          {description && (
            <SDescription dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {buttons && buttons.length > 0 && (
            <SCta>
              {buttons.map((button, index) => (
                <SmartLink
                  {...button}
                  key={index}
                  outboundAppend="utm_source=litmus&utm_medium=homepage&utm_campaign=publications_cta"
                  outboundOnClick={() => {
                    if (
                      button.url.includes(
                        'sciencelife.uchospitals.edu/2016/05/17/tracking-real-time-data-to-personalize-treatment-for-ibd',
                      )
                    ) {
                      trackCustomEvent({
                        category: 'publication',
                        action: 'open',
                        label: 'takeda_study',
                      });
                    }
                  }}
                >
                  <SButton gap="side">{button.text}</SButton>
                </SmartLink>
              ))}
            </SCta>
          )}

          {/* <SContentPattern
            size="contain"
            src="/images/data_pattern_3.png"
            alt=""
          /> */}
        </SContent>

        <SPhoto>
          {image && <SPhotoImage src={image} alt={imageTitle} />}
          <SPhotoPattern
            size="contain"
            src="/images/data_pattern_2.png"
            alt=""
          />
          {imageTitle && <SImageTitle>{imageTitle}</SImageTitle>}
        </SPhoto>

        {/* TODO: CMS */}
        <SQuotationWrapper>
          {text && <div>{text}</div>}
          {author && <SAuthor>{author}</SAuthor>}
          {authorAbout && <SAuthorAbout>{authorAbout}</SAuthorAbout>}
        </SQuotationWrapper>
      </STestimonial>
    </Container>
  );
};

export default Testimonial;
